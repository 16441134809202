import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/halloween-1978-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1978 slasher movie classic, Halloween"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 23</h1>
                    <h2>Halloween (1978)</h2>
                    <h3>October 30, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> John Carpenter // <b>Starring:</b> Jamie Lee Curtis &amp; Donald Pleasance</p>
                        <div className="player">
                            <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/23-Halloween-1978-e2b8fjv" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe! horror podcast episode 23: Halloween (1978)"></iframe>
                        </div>
                        <p><Link to="/transcripts/halloween-1978">Read the episode transcript</Link></p>
                        <p>Bring Me The Axe! concludes its exploration of the Halloween legacy just in time for Halloween with an epic-length love letter to John Carpenter, Debra Hill, and their landmark creation. Halloween represents a major paradigm shift in horror movie and sets the pace for every horror movie that came in its wake. It's elegant. Every piece of the production is working in perfect harmony with the other pieces. The end-product is greater than the sum of its parts. It is a perfect horror movie and it's not surprising in the least that everyone is still obsessed with it.</p>
                        <p>Trailer: <OutboundLink href="https://www.youtube.com/watch?v=T5ke9IPTIJQ" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=T5ke9IPTIJQ</OutboundLink></p>
                        <p>Men, Women, and Chainsaws by Carol Clover: <OutboundLink href="https://amzn.to/3QjtV5h" target="_blank" rel="noreferrer">https://amzn.to/3QjtV5h</OutboundLink></p>
                        <p>Halloween Unmasked: <OutboundLink href="https://open.spotify.com/show/6nwME42dhsc04T0KFzvGgB?si=434ac60f2c88482f" target="_blank" rel="noreferrer">https://open.spotify.com/show/6nwME42dhsc04T0KFzvGgB?si=434ac60f2c88482f</OutboundLink></p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/halloween-2007">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)